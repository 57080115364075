<template>
  <div>Detailed Estimate</div>
</template>

<script>
export default {
  name: "Layout",
  mounted(){  
    let pages = this.$store.state.epitch.ePitch.pages;
    let urlUpload = this.$store.state.epitch.url.concat('upload/');
    let attachment = '';
    pages.forEach(page => {
      if(page.id === "113") {
        if(typeof page.attachment !== 'undefined'){
          attachment = urlUpload.concat(page.attachment)
          window.open(attachment, '_blank');
        }
      }
    });
    this.$router.go(-1);
  }
};
</script>

<style>
</style>